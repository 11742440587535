import {JsonObject, JsonProperty} from "json2typescript";
import InvoiceLine from "@/models/InvoiceLine";
import {DateTime} from "luxon";
import DateTimeConverter from "@/services/converters/DateTimeConverter";

@JsonObject("Invoice")
export default class Invoice {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("clientId", String, true)
    clientId?: string = undefined
    @JsonProperty("total", Number, true)
    total?: number = undefined
    @JsonProperty("date", DateTimeConverter, true)
    date?: DateTime = undefined
    @JsonProperty("invoiceLines", [InvoiceLine], true)
    invoiceLines?: InvoiceLine[] = undefined

}
