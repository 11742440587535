










































































import {Component, Prop, Vue} from "vue-property-decorator";
import Invoice from "@/models/Invoice";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import ConstantTool from "@/services/tool/ConstantTool";
import LangModule from "@/store/LangModule";
import InvoiceService from "@/services/InvoiceService";

@Component
export default class InvoiceDialog extends Vue {
	lang: any = getModule(LangModule).lang
	@Prop() readonly invoice!: Invoice
	@Prop() dialog!: boolean
	headers = [
		{ text: this.lang.reference, value: "product.connectorId", width: "120px" },
		{ text: this.lang.name, value: "product.name", width: "200px" },
		{ text: this.lang.quantity, value: "quantity", width: "120px" },
		{text: this.lang.ratePrice, value: 'taxBase', width: "100px" },
		{text: this.lang.total, value: 'total'},
	]

	sessionModule: SessionModule = getModule(SessionModule)

	get rateId() {
		return this.sessionModule.session.user.rateId
	}

	get total(): number {
		let total = 0
		const values = this.invoice.invoiceLines?.map(e => Number(e.product!.rates![this.rateId! - 1].price) * Number(e.quantity!))
		values?.forEach(e => {total = total + e})
		return total
	}

	get totalQuantity(): number {
		let total = 0
		const values = this.invoice.invoiceLines!.map(e => Number(e.quantity!))
		values.forEach(e => {total = total + e})
		return total
	}

	exportPdf() {
		InvoiceService.getInvoicePdf(this, this.invoice.id!!)
	}

}
