import {Vue} from "vue-property-decorator";
import Invoice from "@/models/Invoice";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";
import axios, {AxiosError, AxiosResponse} from "axios";
import Response from "@/models/responses/Response";


export default class InvoiceService {

    static async getInvoices(
        component: Vue, invoices: Invoice[], page: number, size: number, search: string, sortDateDesc: boolean = true, sortCodeDesc: boolean = true,
        lessThan: number | null, greaterThan: number | null, start: string | null, end: string | null
    ) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/invoices", {
                params: { page, size, search, sortDateDesc, sortCodeDesc, lessThan, greaterThan, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Invoice)
            invoices.splice(0, invoices.length)
            list.forEach(p => invoices.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
            // @ts-ignore
            component.loading = false
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han podido obtener las facturas")
            console.log(err)
        } finally {

        }
    }

    static async getInvoice(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/invoices/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.invoice = JsonTool.jsonConvert.deserializeObject(response.data, Invoice)
            // @ts-ignore
            component.loading = false
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se pudo obtener la factura")
        }
    }

    static async getInvoicePdf(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/invoices/" + id + "/pdf", {
                responseType: "blob",
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            window.open(URL.createObjectURL(response.data));

        } catch (e) {

        } finally {
            // @ts-ignore
            component.loading = false;
        }
    }

    static async synchronizeInvoices2(): Promise<Response<null>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/invoices", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            if (response.status == 200) {
                getModule(SnackbarModule).makeToast(
                    "La sincronización de facturas ha empezado a procesarse y tomará varios minutos en finalizar, se le notificará cuando termine."
                )
            }
            return Promise.resolve({ result: null })
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.status == 500) {
                getModule(SnackbarModule).makeToast("Ya hay una sincronización de esta empresa en proceso.")
            }
            return Promise.reject(e)
        }
    }

    static async synchronizeInvoices(component: Vue) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.post(ConstantTool.BASE_URL + "/api/invoices", null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se pudo sincronizar las facturas")
        } finally {
            // @ts-ignore
            component.loading = false;
        }
    }

    static async getInvoicesCSV(
        component: Vue, page: number, size: number, search: string, sortDateDesc: boolean = true, sortCodeDesc: boolean = true,
        lessThan: number | null, greaterThan: number | null, start: string | null, end: string | null
    ) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/invoices/csv", {
                params: { page, size, search, sortDateDesc, sortCodeDesc, lessThan, greaterThan, start, end },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const blob = new Blob([ response.data ], { "type" : "text/csv" })
            let link = document.createElement('a')
            // @ts-ignore
            link.href = window.URL.createObjectURL(blob)
            link.download = 'invoices.csv'
            // @ts-ignore
            link.click()
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han podido obtener las facturas")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

}